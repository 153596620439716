import * as React from "react";
import { Router, useLocation } from "@reach/router";
import { graphql, Link } from "gatsby";
import Layout from "../../components/common/Layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import {
  categoryColor,
  convertDate,
  isEmpty,
  latestNews,
  truncateString,
} from "../../utils/utils";
import SubscribeNewsletter from "../../components/subscribe_newsletter";

const News = ({ data }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryName = params.get("category");
  let { origin, pathname } = location;
  const newsList = !isEmpty(data?.news?.nodes)
    ? latestNews(data?.news?.nodes)
    : [];
  const categoryColors = data?.categoryColors?.childMdx?.frontmatter;

  const renderNewsList = (news: any, index: number) => {
    return (
      <div className="row mb-4" key={index}>
        <div className="col-md-6 col-lg-4 mb-3">
          <Link to={`/news/${news?.childMdx?.frontmatter?.slug}`}>
            <img
              src={news?.childMdx?.frontmatter?.imageUrl}
              alt=""
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-md-6 col-lg-8">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="mt-1 mb-1">
              <span
                className="badge rounded-pill news-badge"
                style={{
                  backgroundColor: categoryColor(
                    news?.childMdx?.frontmatter?.category,
                    categoryColors
                  ),
                }}
              >
                <Link
                  to={`/news/?category=${news?.childMdx?.frontmatter?.category}`}
                >
                  {news?.childMdx?.frontmatter?.category}
                </Link>
              </span>
            </div>
            {news?.childMdx?.frontmatter?.isSharable && (
              <div className="social-share-links">
                <a
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${origin}/news/${news?.childMdx?.frontmatter?.slug}%2F&display=popup&ref=plugin&src=like&kid_directed_site=0&app_id=113869198637480`}
                >
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M21.3308 11.3208H23.1566V8.14083C22.8416 8.0975 21.7583 8 20.4966 8C17.8641 8 16.0608 9.65583 16.0608 12.6992V15.5H13.1558V19.055H16.0608V28H19.6224V19.0558H22.4099L22.8524 15.5008H19.6216V13.0517C19.6224 12.0242 19.8991 11.3208 21.3308 11.3208Z"
                        fill="#aaaaaa"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width={20}
                          height={20}
                          fill="white"
                          transform="translate(8 8)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${origin}/news/${news?.childMdx?.frontmatter?.slug}`}
                >
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M27.9951 27.9999V27.999H28.0001V20.664C28.0001 17.0757 27.2276 14.3115 23.0326 14.3115C21.0159 14.3115 19.6626 15.4182 19.1101 16.4674H19.0517V14.6465H15.0742V27.999H19.2159V21.3874C19.2159 19.6465 19.5459 17.9632 21.7017 17.9632C23.8259 17.9632 23.8576 19.9499 23.8576 21.499V27.9999H27.9951Z"
                        fill="#aaaaaa"
                      />
                      <path
                        d="M8.33008 14.6475H12.4767V28H8.33008V14.6475Z"
                        fill="#aaaaaa"
                      />
                      <path
                        d="M10.4017 8C9.07583 8 8 9.07583 8 10.4017C8 11.7275 9.07583 12.8258 10.4017 12.8258C11.7275 12.8258 12.8033 11.7275 12.8033 10.4017C12.8025 9.07583 11.7267 8 10.4017 8V8Z"
                        fill="#aaaaaa"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width={20}
                          height={20}
                          fill="white"
                          transform="translate(8 8)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href={`http://twitter.com/share?text=${news?.childMdx?.frontmatter?.title}&url=${origin}/news/${news?.childMdx?.frontmatter?.slug}&hashtags=${news?.childMdx?.frontmatter?.category}`}
                >
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5154 25.5088C21.5912 25.5088 25.4607 19.6466 25.4607 14.5634C25.4607 14.3969 25.4573 14.2311 25.4498 14.0663C26.2009 13.5232 26.8537 12.8455 27.3687 12.0742C26.6795 12.3807 25.9376 12.5867 25.1596 12.68C25.9538 12.2035 26.5636 11.4502 26.8511 10.5521C26.1079 10.9928 25.2847 11.3129 24.4084 11.4858C23.7065 10.7381 22.7069 10.2705 21.6003 10.2705C19.476 10.2705 17.7532 11.9932 17.7532 14.1168C17.7532 14.4188 17.787 14.7124 17.8529 14.994C14.6557 14.8331 11.8205 13.3025 9.92342 10.9748C9.59319 11.5432 9.40261 12.2036 9.40261 12.9084C9.40261 14.2428 10.0818 15.4212 11.1144 16.1104C10.4833 16.091 9.89051 15.9177 9.37223 15.6294C9.37166 15.6455 9.37166 15.6612 9.37166 15.6784C9.37166 17.5415 10.6976 19.097 12.4581 19.4495C12.1347 19.5375 11.7945 19.5848 11.4436 19.5848C11.1962 19.5848 10.9549 19.5605 10.7206 19.5155C11.2103 21.0439 12.6305 22.1562 14.3143 22.1874C12.9976 23.2194 11.339 23.8341 9.53631 23.8341C9.22626 23.8341 8.91976 23.8163 8.61865 23.7808C10.3212 24.872 12.3428 25.5089 14.5155 25.5089"
                      fill="#aaaaaa"
                    />
                  </svg>
                </a>
              </div>
            )}
          </div>
          <h4 className="news-title">
            <Link to={`/news/${news?.childMdx?.frontmatter?.slug}`}>
              {news?.childMdx?.frontmatter?.title}
            </Link>
          </h4>
          <div className="post-intro">
            <p>{truncateString(news?.childMdx?.frontmatter?.summary, 250)}</p>
          </div>
          <p className="news-date text-muted">
            <span id="posted-by">{news?.childMdx?.frontmatter?.postedBy}</span>{" "}
            |{" "}
            <span id="posted-date">
              {convertDate(news?.childMdx?.frontmatter?.date)}
            </span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="news-list-page">
        <div className="row">
          <div className="col-sm-6">
            <p className="sub-text back-link mb-1">
              <Link to="/news">News</Link>
            </p>
            <h2 className="mb-4">Latest Updates</h2>
          </div>
          <div className="filtered-badge col-sm-6">
            <div className="text-end">
              {categoryName && (
                <span
                  className="badge rounded-pill news-filter-badge"
                  style={{
                    backgroundColor: categoryColor(
                      categoryName,
                      categoryColors
                    ),
                  }}
                >
                  {categoryName}{" "}
                  <Link to="/news">
                    <i>&times;</i>
                  </Link>
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="mb-12 mb-5">
          <SubscribeNewsletter contentAlign="center" />
        </div>

        {newsList.map((news, index) => {
          if (
            categoryName &&
            categoryName === news?.childMdx?.frontmatter?.category
          )
            return renderNewsList(news, index);
          if (!categoryName) return renderNewsList(news, index);
        })}
      </div>
    </Layout>
  );
};
export default News;

export const query = graphql`
  query news {
    news: allFile(filter: { sourceInstanceName: { eq: "news" } }) {
      nodes {
        childMdx {
          frontmatter {
            title
            imageUrl
            date
            postedBy
            url
            category
            slug
            summary
            isSharable
          }
          body
        }
      }
    }

    categoryColors: file(sourceInstanceName: { eq: "categoryColors" }) {
      childMdx {
        frontmatter {
          Partnership
          Products
          Technology
        }
      }
    }
  }
`;
